<template>
    <div>
        <table id="mytable">
            <thead
              :style="{
                color: 'white !important',
                borderBottom: '1px solid black',
              }"
            >
              <th
                class="text-no-wrap"
                style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                  width: 300px;
                "
              >
                ລະຫັດພະນັກງານ
              </th>
              <th
                class="text-no-wrap"
                style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                "
              >
                ຊື່,ນາມສະກຸນ
              </th>
              <th
                class="text-no-wrap"
                style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                "
              >
                ເພດ
              </th>
              
              <th
                class="text-no-wrap"
                style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                  width: 300px;
                "
              >
                ທະນາຄານ
              </th>
              <th
                class="text-no-wrap"
                style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                  width: 300px;
                "
              >
                ຊື່ບັນຊີທະນາຄານ
              </th>
              
            </thead>
            <tbody>
              <tr v-for="(item, index) in employees" :key="index">
                <td>
                  <div v-if="item.emp_number.error_status">
                    <div v-if="item.old == false">
                      <span class="text-danger">{{
                        item.emp_number.value
                      }}</span>
                      <v-text-field
                        outlined
                        class="text-change"
                        dense
                        v-model="editData.emp_number"
                        @change="editImportingData(item.id, 'emp_number')"
                      ></v-text-field>
                    </div>
                    <div v-else>
                      <!-- <v-text-field
                        outlined
                        class="text-change"
                        dense
                        v-model="item.emp_number.value"
                        @change="editImportingEmpNumber(item.id, 'emp_number', item.emp_number.value)"
                      ></v-text-field> -->
                      <span>{{ item.emp_number.value }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="item.old == true">
                      <v-text-field
                        outlined
                        class="text-change"
                        dense
                        v-model="item.emp_number.value"
                        @change="editImportingEmpNumber(item.id, 'emp_number', item.emp_number.value)"
                      ></v-text-field>
                    </div>
                    <div v-else>{{ item.emp_number.value }}</div>
                    
                  </div>
                </td>
                <td>
                  <span v-if="item.old == true" color="red">
                    {{ item.name }} {{ item.surname }}
                  </span>
                  <span v-else>
                    {{ item.name }} {{ item.surname }}
                  </span>
                </td>
                <td>{{ item.gender }}</td>
                
                <td>
                  <div v-if="item.bank_name.error_status">
                    <v-select
                      :items="item.bank_name.data"
                      outlined
                      dense
                      color="error"
                      class="text-change"
                      item-value="short_name"
                      item-text="name"
                      v-model="editData.bank_name"
                      @change="editImportingData(item.id, 'bank_name')"
                    ></v-select>
                  </div>
                  <div v-else>{{ item.bank_name.value }}</div>
                </td>
                <td>
                  <div v-if="!item.bank_name.error_status">
                    {{ item.bank_account_name }} <br>
                    {{ item.bank_account }}
                  </div>
                </td>
                
              </tr>
            </tbody>
          </table>
    </div>
</template>

<script>
    export default {
        props:{
            employees:{},
        },
        data() {
          return {
            editData: {
              scan_outside: "",
              finger_scan: "",
              emergency_contact: "",
              job_grade: "",
              branch: "",
              position: "",
              department: "",
              card_type: "",
              nationality: "",
              contact_type: "",
              district: "",
              bank_name: "",
              emp_number: "",
              coloumn: "",
            },
          }
        },
        methods: {
          clear(){
            this.editData.bank_name = '';
          },
          editImportingData(id, column){
            this.editData.coloumn = column;
            this.$emit('success', {id:id, editData:this.editData});
            this.clear();
          },
          editImportingEmpNumber(import_id, coloumn, emp_number){
            this.editData.emp_number = emp_number;
            this.editImportingData(import_id, coloumn);
            this.clear();
          },
        },
        created() {
            
        },
    }
</script>

<style scoped lang="scss">
.text-change {
  font-size: 12px !important;
  padding: 0px !important;
  width: 150px;
  color: red !important;
}
.main-container {
  margin: 10px !important;
  padding-bottom: 0px !important;
}
.section-action {
  height: auto;
  margin-top: 20px;
}
.section-form-content {
  margin-top: 25px !important;
  padding: 0px;
}
.highlight--schedule {
  .schedule--byEmployee {
    background-color: rgba(0, 168, 255, 0.15);
    color: #719aff;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }

  .schedule--byPosition {
    background-color: rgba(239, 87, 119, 0.2);
    color: #f82c80;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }
}

.container-report {
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  padding: 10px;
  h1 {
    font-family: $font-family;
    font-size: 1.5rem;
    background-color: #ffffff;
    color: blue;
  }

  .card-report {
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-x: auto;
    //background-color: #FFFFFF;
    padding: 15px 0;
    font-family: $font-family;
    background-color: #ffffff;
    margin-bottom: 5px;
  }
}

.card-color-detail {
  margin-bottom: 25px !important;
  .color-detail {
    width: 100%;
    height: 80%;
    display: flex;
    border: 1px solid #eeeeee;
    align-items: center;
    border-radius: 16px;
    padding: 10px 10px;
    font-size: 14px !important;

    .circle-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: red;
    }

    p {
      font-family: $font-family;
      margin-bottom: 0;
      padding-left: 20px;
    }

    .standard {
      background-color: #ffffff;
      border: 1px solid #000000;
    }
    .is-leave {
      background-color: #80acf4;
    }

    .is-absence {
      background-color: red;
    }

    .is-skip {
      background-color: #fd9644;
    }

    .is-time-difference {
      background-color: #2f3542;
    }
  }
}

#mytable {
  font-family: $font-family;
  text-transform: capitalize;
  font-size: 12px !important;
  td {
    border: 1px solid;
  }
  .roster-chip {
    color: white;
    background: rgb(225, 175, 39) !important;
  }
  .emp-roster:hover {
    background: rgb(212, 212, 212);
  }
  .sum-work-sheet {
    color: blue;
    border: 1px solid #eae9e9;
    text-align: center;
    padding: 5px;
  }
  .ma-2 {
    font-size: 12px !important;
    margin: 2px !important;
  }
}

.td-part-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: 1px solid #000000;
}
</style>