<template>
    <div class="main-container">
        <div class="section-topic">
            <div class="title-header">
                <h1></h1>
            </div>
        </div>
        <div class="content">
            <v-card>
                <v-list two-line>
                    <template v-for="(item, index) in data">
                        <v-subheader v-if="index == 0">
                            <h3 class="text-h5 text-danger">The contract is about to exprice</h3>
                            <v-btn class="btn-export ml-4" @click="exportExcel()">
                                <span><i class="fal fa-file-export" style="margin-right: 4px"></i></span>Export Excel
                            </v-btn>
                        </v-subheader>
                        <v-divider :inset="true"></v-divider>
                        <v-list-item>

                            <v-list-item-avatar>
                                <img :src="item.profile">
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title><span :class="setColor(item.contract_expired)">{{ item.emp_number }} {{
                                    item.name }} {{ item.surname }}</span></v-list-item-title>
                                <v-list-item-subtitle class="lfont mx-6">
                                    <span>Department: </span> <span>{{ item.dept.name }}</span>
                                    <span> Position: </span><span v-for="(position_item, i) in item.position">{{
                                        position_item.name }} {{ i == item.position.length - 1 ? '' : ',' }}</span>
                                    <span>Branch: </span> <span>{{ item.branch.name }}</span>
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    Contract <span :class="setColor(item.contract_expired)"><span>start:</span> {{
                                        item.start_date }} <span>end:</span> {{ item.end_date }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>

            </v-card>
        </div>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
    components: {
        Loading,
    },
    data() {
        return {
            isLoading: false,
            data: [],
            filter: "",
            department_id: "",
            branch_id: "",
        }
    },
    methods: {
        fetchDashboard() {
            this.isLoading = true;
            this.$axios
                .get(`/company/employee/contract-expire`)
                .then((res) => {
                    if (res.status === 200) {
                        this.data = res.data.data;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        exportExcel() {
            let emp_id = this.data.map(item => item.id);
            this.isLoading = true;
            this.$axios.get(`export/employees/to/excel`, {
                responseType: "blob",
                params: {
                    filter: this.filter,
                    department_id: this.department_id,
                    branch_id: this.branch_id,
                    employee_id: [1356, 1359, 1355, 1360]
                },
            }).then((res) => {
                this.isLoading = false;
                const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                const fileLink = document.createElement("a");
                fileLink.href = fileUrl;
                fileLink.setAttribute("download", "employee.xlsx");
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        setColor(status) {
            if (status == 'expired')
                return 'text-danger';
            if (status == 'warning')
                return 'text-warning';
        }
    },
    created() {
        this.fetchDashboard();
    },
}
</script>

<style lang="scss" scoped>
.btn-export {
    background: rgb(67, 184, 69) !important;
    color: white !important;
}
</style>