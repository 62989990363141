<template>
  <div class="step-layout">
    <div class="step-content">
      <v-form class="form" ref="form">
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1>
                <span><i class="far fa-calendar-alt"></i></span>{{ $t("Employee.form.holiday") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col class="d-flex flex-row pa-0" cols="12" md="12" v-for="(item, index) in employee_holidays"
                :key="index">
                <v-col cols="5" md="5">
                  <label class="label-input"><span class="text-alert">*</span>
                    {{ $t("Employee.form.selectHoliday") }}</label>
                  <p class="employee-holiday-item lfont">
                    {{ item.name }}
                  </p>
                </v-col>
                <v-col cols="3" md="3">
                  <label class="label-input"><span class="text-alert">*</span>
                    {{ $t("Employee.form.holidayAmount") }}</label>
                  <p class="employee-holiday-item lfont">
                    {{ item.amount }}
                  </p>
                </v-col>
                <v-col cols="4" md="4">
                  <label class="label-input"><span class="text-alert">*</span>
                    {{ $t("Employee.form.holidayRemaining") }}</label>
                  <div class="remining-qty">
                    <v-text-field dense outlined v-model="item.day_used" type="number" class="input-number"
                      :hint="`${server_errors.day_used}`" persistent-hint :placeholder="'Day'">
                    </v-text-field>
                    <v-text-field dense outlined v-model="item.hour_used" type="number" class="input-number"
                      :hint="`${server_errors.day_used}`" persistent-hint :placeholder="'Hour'">
                    </v-text-field>
                  </div>
                </v-col>
                <span>
                  <div class="removeContact">
                    <i class="fas fa-minus-circle" @click="removeFieldHoliday(item)"></i>
                  </div>
                </span>
              </v-col>
              <v-col cols="12">
                <div class="btn-addFieldContact" @click="dialog_add_holiday = true">
                  <span class="mr-2"><i class="fal fa-plus"></i></span>{{ $t("Employee.form.addMore") }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12" md="12">
          <v-btn class="btn-next btn-salary btn-save-change" style="float: right" @click="validateForm()"
            :loading="btnLoading">
            ບັນທືກຂໍ້ມູນ<span><i class="fal fa-long-arrow-right"></i></span>
          </v-btn>
        </v-col>
      </v-form>
    </div>
    <modalAddCompanyHoliday :dialog_add_holiday="dialog_add_holiday" :employee_holidays="employee_holidays"
      @close="(val) => (dialog_add_holiday = val)" @add="(val) => addFieldHoliday(val)" />
  </div>
</template>

<script>
import modalAddCompanyHoliday from '../modal/modalAddCompanyHoliday.vue';
export default {
  components: {
    modalAddCompanyHoliday
  },
  data() {
    return {
      btnLoading: false,
      dialog_add_holiday: false,
      nameRules: [(v) => !!v || "ກະລຸນາປ້ອນ..."],
      server_errors: {
        id: "",
        amount: "",
        day_used: "",
        hour_used: "",
      },
      holiday_select:
      {
        id: "",
        name: "",
        amount: "",
        day_used: "",
        hour_used: "",
      }
      ,
      employee_holidays: [],
    };
  },
  methods: {
    isFormError() {
      const form = this.$refs.form;
      return Object.keys(form.errorBag).some((key) => form.errorBag[key]);
    },
    addFieldHoliday(val) {
      this.employee_holidays.push({
        id: val.id,
        name: val.name,
        amount: val.amount,
        day_used: val.day_used,
        hour_used: val.hour_used,
      });
    },

    removeFieldHoliday(item) {
      let idx = this.employee_holidays.indexOf(item);
      this.employee_holidays.splice(idx, 1);
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },
    saveChange() {
      this.btnLoading = true;
      const items = {
        employee_holidays: this.employee_holidays ?? [],
      };
      this.$store.commit("Employee/EMPLOYEE_HOLIDAY", items);
      this.$emit("success");
      this.btnLoading = false;
    },
  },
  created() {

  },
};
</script>

<style scoped lang="scss">
.remining-qty {
  display: flex;
  justify-content: space-around;
}

.employee-holiday-item {
  border: 1px solid rgb(164, 164, 164);
  border-radius: 5px;
  padding: 8px;
}

.form {
  margin-top: 40px;
  width: 900px;
  height: auto;
  margin-bottom: 80px;

  .form-title {
    font-family: $font-family;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-weight: normal;
      color: #0978ea;

      span {
        color: #0978ea;
        margin-right: 5px;
      }
    }
  }

  .personal-input {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
  }

  .removeContact {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i {
      font-size: 1.4rem;
      margin-top: 8px;
      color: #ff3d6a;
      cursor: pointer;
    }
  }

  .btn-addFieldContact {
    width: 200px;
    height: 41px;
    background-color: $main-btn-color;
    margin-bottom: 30px;
    border-radius: 4px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: $font-family;
  }

  .btn-salary {
    width: 200px;
  }
}
</style>
