<template>
  <div class="step-layout">
    <div class="step-content">
      <v-form class="form" ref="form" @submit.prevent="validateForm">
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1>
                <span><i class="far fa-usd-circle"></i></span>{{ $t("Employee.form.work_info") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="8" md="8">
            <v-row class="personal-input">
              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input">{{
                  $t("Employee.form.position")
                }}</label>
                <!-- <v-select
                  outlined
                  multiple
                  dense
                  :items="position"
                  item-value="id"
                  item-text="name"
                  v-model="items.positionId"
                  :rules="nameRules"
                  :hint="`${server_errors.positionId}`"
                  persistent-hint
                  requried
                >
                </v-select> -->
                <v-autocomplete v-model="items.positionId" :items="position" outlined dense chips small-chips
                  item-value="id" item-text="name" multiple :rules="nameRules" :hint="`${server_errors.positionId}`"
                  persistent-hint requried></v-autocomplete>

                <div class="add-village" @click="addNewPosition">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>

              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input">
                  <span class="text-alert">*</span>
                  {{ $t("Employee.form.department") }}</label>
                <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment"
                  hide-selected :rules="nameRules" :hint="`${server_errors.company_department_id}`" persistent-hint
                  requried v-model="items.company_department_id" :error-messages="`${this.salaryEmployeeError &&
                    this.salaryEmployeeError.company_department_id
                    ? this.salaryEmployeeError.company_department_id
                    : ''
                    }`
                    ">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <div class="add-village" @click="addNewDepartment">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>

              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input">
                  {{ $t("Employee.form.employee_level") }}</label>
                <v-select outlined dense item-value="id" item-text="name" :items="employeeLevel" v-model="items.level_id"
                  :hint="`${server_errors.level_id}`" persistent-hint :error-messages="`${this.salaryEmployeeError &&
                    this.salaryEmployeeError.level_id
                    ? this.salaryEmployeeError.level_id
                    : ''
                    }`
                    ">
                </v-select>
                <div class="add-village" @click="addNewEmployeeLevel">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>
              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input">
                  <span class="text-alert">*</span>
                  {{ $t("Employee.form.branch") }}</label>
                <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="branches"
                  hide-selected :rules="nameRules" :hint="`${server_errors.company_branch_id}`" persistent-hint requried
                  v-model="items.company_branch_id" :error-messages="`${this.salaryEmployeeError &&
                    this.salaryEmployeeError.company_branch_id
                    ? this.salaryEmployeeError.company_branch_id
                    : ''
                    }`
                    ">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <div class="add-village" @click="addNewBranch">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>

              <!-- Job-Title -->
              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input">
                  {{ $t("Employee.form.jobTitle") }}</label>
                <v-autocomplete 
                  filled 
                  dense 
                  item-text="name" 
                  item-value="id" 
                  outlined 
                  clearable
                  :items="jobTitles"
                  hide-selected 
                  persistent-hint 
                  v-model="items.job_title_id" :error-messages="`${this.salaryEmployeeError &&
                    this.salaryEmployeeError.job_title_id
                    ? this.salaryEmployeeError.job_title_id
                    : ''
                    }`
                ">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <!-- <div class="add-village" @click="addNewBranch">
                  <i class="fal fa-plus"></i>
                </div> -->
              </v-col>  
              <!-- End Job-Title -->

              <!-- Education-Level -->
              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input">
                  {{ $t("Employee.form.educationLevel") }}</label>
                <v-autocomplete filled dense item-text="name" item-value="id" outlined clearable :items="educationLevels"
                  hide-selected persistent-hint
                  v-model="items.education_level_id" :error-messages="`${this.salaryEmployeeError &&
                    this.salaryEmployeeError.education_level_id
                    ? this.salaryEmployeeError.education_level_id
                    : ''
                    }`
                    ">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <!-- <div class="add-village" @click="addNewBranch">
                  <i class="fal fa-plus"></i>
                </div> -->
              </v-col>  
              <!-- End Education-Level -->

              <!-- Working Years -->
              <v-col cols="8" md="8" style="margin-top: -30px;">
                <v-checkbox 
                  v-model="items.is_annual_bonus" 
                  :label="$t('workingYear.is_working_years')" 
                  hide-details
                  class="lfont"
                >
                </v-checkbox>
              </v-col>
              <!-- End Working Years -->

              <v-col cols="8" md="8" style="margin-top: 20px;">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.employment_types")
                }}</label>
                <v-radio-group row v-model="items.employment_type">
                  <v-radio class="label-input" value="PartTime" label="PartTime">
                  </v-radio>
                  <v-radio class="label-input" value="FullTime" label="FullTime">
                  </v-radio>
                  <span class="error-validate mt-1" v-if="salaryEmployeeError && salaryEmployeeError.employment_type
                    ">* !!</span>
                </v-radio-group>
              </v-col>
              <v-col cols="8" md="8" class="mb-0">
                <label class="label-input">
                  <span class="text-alert">*</span>
                  {{ $t("Employee.form.start Date") }}</label>
                <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                  :append-to-body="true" v-model="items.start_date" :rules="nameRules" requried
                  :hint="`${server_errors.start_date}`" persistent-hint></DatePicker>
                <span class="error-validate" v-if="salaryEmployeeError && salaryEmployeeError.start_date">* !!</span>
              </v-col>
              <v-col cols="8" md="8" class="mb-8">
                <v-checkbox v-model="setEndContract" :label="$t('Employee.form.end_date')" hide-details
                  class="lfont"></v-checkbox>
                <div v-if="setEndContract">
                  <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                    :append-to-body="true" v-model="items.end_date" :rules="nameRules" requried
                    :hint="`${server_errors.end_date}`" persistent-hint></DatePicker>
                  <span class="error-validate" v-if="salaryEmployeeError && salaryEmployeeError.end_date">* !!</span>
                  <!-- <div class="choose-image">
                    <div class="show-image">
                      <i class="fas fa-cloud-upload-alt"></i>
                      <input
                        @change="PreviewImage"
                        type="file"
                        class="input-file-image"
                      />
                      <img v-if="showImage" :src="showImage" alt="" />
                    </div>
                  </div> -->
                </div>

              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <span v-for="(item, index) in ManageSalaryPermission" :key="index">
          {{ item }}
        </span>

        <span v-if="ManageSalaryPermission">
          <v-row>
            <v-col cols="5" md="3">
              <div class="form-title">
                <h1>
                  <span><i class="far fa-usd-circle"></i></span>{{ $t("Employee.form.salary") }}
                </h1>
              </div>
            </v-col>
            <v-col cols="8" md="8">
              <v-row class="personal-input">
                <v-col cols="8" md="8">
                  <label class="label-input">
                    <span class="text-alert">*</span>
                    {{ $t("Employee.form.baseSalary") }}</label>
                  <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber"
                    v-model="items.base_salary" :hint="`${server_errors.base_salary}`" persistent-hint type="number"
                    :rules="nameRules" required :error-messages="`${this.salaryEmployeeError &&
                      this.salaryEmployeeError.base_salary
                      ? this.salaryEmployeeError.base_salary
                      : ''
                      }`
                      " />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" md="3">
              <div class="form-title">
                <h1>
                  <span><i class="fal fa-money-check-edit-alt"></i></span>{{ $t("Employee.form.benefit") }}
                </h1>
              </div>
            </v-col>
            <v-col cols="7" md="9">
              <v-row class="personal-input">
                <v-col class="d-flex flex-row pa-0" cols="12" md="12" v-for="(item, index) in benefits" :key="index">
                  <v-col cols="4" md="4">
                    <label class="label-input">{{
                      $t("Employee.form.benefit")
                    }}</label>
                    <v-select outlined dense v-model="item.benefit_id" :items="listBenefits"
                      :hint="`${server_errors.benefit_id}`" persistent-hint item-value="id" item-text="name">
                    </v-select>
                  </v-col>
                  <v-col cols="8" md="8">
                    <label class="label-input">{{
                      $t("Employee.form.benefitValue")
                    }}</label>
                    <vuetify-money :outlined="true" :options="$store.state.formatSalaryNumber" :clearable="true" dense
                      v-model="item.value" type="number" class="input-number" :hint="`${server_errors.value}`"
                      persistent-hint />
                  </v-col>
                  <span>
                    <div class="removeContact">
                      <i class="fas fa-minus-circle" @click="removeFieldBenefit(item)"></i>
                    </div>
                  </span>
                </v-col>
                <v-col cols="12">
                  <div class="btn-addFieldContact" @click="addFieldBenefit()">
                    <span class="mr-2"><i class="fal fa-plus"></i></span>{{ $t("Employee.form.addMore") }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </span>

        <v-row>
          <v-col cols="12" md="12">
            <div class="form-title mt-8">
              <h1>
                <span><i class="fas fa-poll-people"></i></span>{{ $t("Employee.form.choose") }} HR
                / Line Manager
              </h1>
            </div>
          </v-col>
          <v-col cols="12" md="12">
            <v-row class="personal-input">
              <v-col cols="3" md="3" lg="3">
                <v-switch inset class="label-input" style="width: min-content" v-model="hasSelectRole"
                  :label="$t('Employee.form.choose')"></v-switch>
              </v-col>
              <v-col cols="8" md="8" lg="8" class="mt-4">
                <v-select outlined dense :disabled="!hasSelectRole" :items="listRoleUser" item-text="display_name"
                  item-value="id" multiple v-model="items.roleId">
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title mt-8">
              <h1>
                <span><i class="fas fa-fingerprint"></i></span>{{ $t("Employee.form.fingerScan") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="12" md="5" lg="5">
                <label class="error-validate">{{
                  server_errors.finger_scan
                }}</label>
                <v-radio-group row v-model="items.finger_scan">
                  <v-radio class="label-input" value="yes" :label="$t('Employee.form.yes')">
                  </v-radio>
                  <v-radio class="label-input" value="no" :label="$t('Employee.form.no')">
                  </v-radio>
                  <span class="error-validate mt-1" v-if="salaryEmployeeError && salaryEmployeeError.start_date">*
                    !!</span>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="5" lg="5">
                <div class="scan-outside" v-if="items.finger_scan == 'yes'">
                  <v-checkbox v-model="items.scan_outside" :label="$t('Employee.form.scan_outside')"></v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- ################################################################################## -->

        <v-row v-if="companyProfile['include_salary_tax'] == 'yes'">
          <v-col cols="5" md="3">
            <div class="form-title mt-8">
              <h1>
                <span><i class="fal fa-money-check-edit-alt"></i></span>{{ $t("Employee.form.tax") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="12" md="12" lg="12">
                <label class="error-validate">{{
                  server_errors.include_salary_tax
                }}</label>
                <v-radio-group row v-model="items.include_salary_tax">
                  <v-radio class="label-input" value="employee" :label="$t('Employee.form.employeeTax')">
                  </v-radio>
                  <v-radio class="label-input" value="company" :label="$t('Employee.form.companyTax')">
                  </v-radio>
                  <span class="error-validate mt-1" v-if="salaryEmployeeError &&
                    salaryEmployeeError.include_salary_tax
                    ">*ກະລຸນາເລືອກ່ອນ</span>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- ################################################################################## -->
        <v-row v-if="companyProfile['sso_charge'] == 'employee'">
          <v-col cols="12" md="3">
            <div class="form-title mt-8">
              <h1>
                <span><i class="fal fa-money-check-edit-alt"></i></span>{{ $t("Employee.form.socialSecurity") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="12" md="5">
            <v-row class="personal-input">
              <v-col cols="12" md="12" lg="12">
                <label class="error-validate">{{ server_errors.is_sso }}</label>
                <v-radio-group row v-model="items.is_sso">
                  <v-radio class="label-input" value="yes" :label="$t('Employee.form.socialSecurityYes')">
                  </v-radio>
                  <v-radio class="label-input" value="no" :label="$t('Employee.form.socialSecurityNO')">
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <label class="label-input">.</label>
            <v-text-field v-model="items.sso_number" :placeholder="$t('Employee.form.sso_number')" outlined
              dense></v-text-field>
            <label class="error-validate">{{ server_errors.sso_number }}</label>
          </v-col>
        </v-row>
        <v-col cols="12" md="12">
          <v-btn class="btn-next btn-salary btn-save-change" style="float: right" type="submit" :loading="btnLoading">
            {{ $t("Employee.form.next") }}
            <span><i class="fal fa-long-arrow-right"></i></span>
          </v-btn>
        </v-col>
      </v-form>
    </div>
    <modalAddPosition :visible="modalAddPosition" @success="fetchPosition" @selectCurrentPosition="selectCurrentPosition"
      @change="(val) => (modalAddPosition = val)" />
    <modalAddDepartment :visible="modalAddDepartment" @success="fetchDepartment" @department="selectNewDepartment"
      @change="(val) => (modalAddDepartment = val)" />
    <modalAddBranch :visible="modalAddBranch" @success="fetchBranch" @selectCurrentBranch="selectCurrentBranch"
      @change="(val) => (modalAddBranch = val)" />
    <modalEmployeeLevel :visible="modalEmployeeLevel" @success="fetchEmployeeLevel"
      @selectCurrentEmployeeLevel="selectCurrentEmployeeLevel" @change="(val) => (modalEmployeeLevel = val)" />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import modalAddPosition from "@/components/employee/modal/modalAddPosition";
import modalAddDepartment from "@/components/employee/modal/modalAddDepartment";
import modalAddBranch from "@/components/employee/modal/modalAddBranch";
import modalEmployeeLevel from "@/components/employee/modal/modalEmployeeLevel";

export default {
  props: {
    salaryEmployeeError: {},
  },
  components: {
    DatePicker,
    modalAddPosition,
    modalAddDepartment,
    modalAddBranch,
    modalEmployeeLevel,
  },
  data() {
    return {
      setEndContract: false,
      ManageSalaryPermission: false,
      btnLoading: false,
      hasSelectRole: false,
      modalAddPosition: false,
      modalAddDepartment: false,
      modalAddBranch: false,
      modalEmployeeLevel: false,
      nameRules: [(v) => !!v || "ກະລຸນາປ້ອນ..."],
      employeeLevel: [],
      listDepartment: [],
      items: {
        include_salary_tax: null,
        base_salary: 0,
        positionId: "",
        company_branch_id: "",
        employment_type: "",
        start_date: "",
        end_date: "",
        level_id: "",
        finger_scan: "",
        scan_outside: false,
        is_line_manager: "",
        is_sso: "",
        sso_number: "",
        company_department_id: "",
        roleId: "",
        education_level_id: "",
        job_title_id: "",
        is_annual_bonus: false
      },
      showImage: "",

      server_errors: {
        include_salary_tax: "",
        base_salary: "",
        positionId: "",
        company_branch_id: "",
        employment_type: "",
        start_date: "",
        end_date: "",
        level_id: "",
        benefit_id: "",
        value: "",
        finger_scan: "",
        is_sso: "",
        sso_number: "",
        is_line_manager: "",
        company_department_id: "",
        education_level_id: "",
        job_title_id: "",
        is_annual_bonus: ""
      },
      benefits: [
        /* {
          benefit_id: "",
          value: "",
        }, */
      ],
      position: [],
      branches: [],
      listBenefits: [],
      employeeType: [],
      listRoleUser: [],
      somethingRole: [],
      companyProfile: [],
      jobTitles: [],
      educationLevels: []
    };
  },
  methods: {
    PreviewImage: function (event) {
      this.isLoading = true;
      const input = event.target;

      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be jpeg,png"
        );
      } else if (input.files && input.files[0]) {
        this.imageFile = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadImage(this.imageFile);
      }
    },
    UploadImage(imageFile) {
      let formData = new FormData();
      formData.append("imageFile", imageFile);
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.items.profile = res.data.filename;
          this.isLoading = false;
          console.log(this.items.profile);
        }
      });
    },
    isFormError() {
      const form = this.$refs.form;
      return Object.keys(form.errorBag).some((key) => form.errorBag[key]);
    },
    async selectCurrentBranch(val) {
      if (val === 200) {
        await this.fetchBranch();
        const data = JSON.parse(JSON.stringify(this.branches));
        this.items.company_branch_id = data.pop().id;
      }
    },
    async selectCurrentEmployeeLevel(val) {
      if (val === 200) {
        await this.fetchEmployeeLevel();
        const data = JSON.parse(JSON.stringify(this.employeeLevel));
        this.items.level_id = data.pop().id;
      }
    },
    //for auto select in v-select position
    async selectCurrentPosition(val) {
      if (val === 200) {
        await this.fetchPosition();
        const data = JSON.parse(JSON.stringify(this.position));
        this.items.positionId = data.pop().id;
      }
    },
    //for auto select in v-select department
    async selectNewDepartment(val) {
      if (val === 200) {
        await this.fetchDepartment();
        const data = JSON.parse(JSON.stringify(this.listDepartment));
        this.items.company_department_id = data.pop().id;
      }
    },
    addNewBranch() {
      this.modalAddBranch = true;
    },
    addNewEmployeeLevel() {
      this.modalEmployeeLevel = true;
    },
    addNewPosition() {
      this.modalAddPosition = true;
    },
    addNewDepartment() {
      this.modalAddDepartment = true;
    },
    addFieldBenefit() {
      this.benefits.push({
        benefit_id: "",
        value: "",
      });
    },
    removeItemFromTable(item) {
      const index = this.data.employee.indexOf(item.id);
      if (index >= 0) {
        this.data.employee.splice(index, 1);
      }
    },
    removeFieldBenefit(item) {
      let idx = this.benefits.indexOf(item);
      this.benefits.splice(idx, 1);
    },
    fetchPosition() {
      return this.$axios
        .get(`company/all-position`)
        .then((res) => {
          if (res.data.code === 200) {
            this.position = res.data.data;
            this.position.reverse();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchBranch() {
      return this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.branches = res.data.companyBranches;
            this.branches.reverse();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchBenefits() {
      this.$axios
        .get(`company/list/benefits`)
        .then((res) => {
          if (res) {
            this.listBenefits = res.data.benefits;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchDepartment() {
      return this.$axios.get(`company/list/departments`).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
          this.listDepartment.reverse();
        }
      });
    },
    fetchEmployeeLevel() {
      return this.$axios.get(`company/list/employee/levels`).then((res) => {
        if (res.status === 200) {
          this.employeeLevel = res.data.listLevels;
          this.employeeLevel.reverse();
        }
      });
    },
    async getRoleUser() {
      this.$axios
        .get(`role/hr/linemanager`)
        .then((res) => {
          this.listRoleUser = res.data.data;
        })
        .catch(() => { });
    },

    // Add News
    async fetchComJobtitle() {
      return this.$axios.get(`company/jobtitle`).then((res) => {
        if (res.status === 200) {
          this.jobTitles = res.data.data;
          this.jobTitles.reverse();
        }
      });
    },

    async fetchComEducationLevel() {
      return this.$axios.get(`company/education-level`).then((res) => {
        if (res.status === 200) {
          this.educationLevels = res.data.data;
          this.educationLevels.reverse();
        }
      });
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },
    saveChange() {
      this.btnLoading = true;
      const items = {
        benefits: this.benefits ?? [],
        positionId: this.items.positionId ? this.items.positionId : [],
        base_salary: this.items.base_salary ? this.items.base_salary : 0,
        include_salary_tax:
          this.companyProfile["include_salary_tax"] == "yes"
            ? this.items.include_salary_tax
            : "company",
        company_branch_id: this.items.company_branch_id,
        employment_type: this.items.employment_type,
        start_date: this.items.start_date,
        end_date: this.items.end_date,
        setEndContract: this.setEndContract,
        level_id: this.items.level_id,
        finger_scan: this.items.finger_scan,
        scan_outside: this.items.scan_outside ? "yes" : "no",
        is_line_manager: this.items.is_line_manager,
        is_sso:
          this.companyProfile["sso_charge"] == "employee"
            ? this.items.is_sso
            : "no",
        sso_number: this.items.sso_number,
        company_department_id: this.items.company_department_id,
        roleId: this.items.roleId ? this.items.roleId : [],
        job_title_id: this.items.job_title_id,
        education_level_id: this.items.education_level_id,
        is_annual_bonus: this.items.is_annual_bonus
      };
      this.$axios
        .post(`company/validate/info/employee/4`, items)
        .then((res) => {
          if (res.status === 200) {
            this.btnLoading = false;
            this.$store.commit("Employee/SALARY_ITEM", items);
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
          if (error.response.status === 500) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              "server error"
            );
          }
        });
    },
    async getCompanyProfile() {
      this.companyProfile = JSON.parse(
        localStorage.getItem("sng-company-profile")
      );
    },

    async getUserPermission() {
      let UserPermission = JSON.parse(localStorage.getItem("userPermission"));
      let user_role = JSON.parse(localStorage.getItem("user_role"));
      if (user_role == "companyadmin") {
        this.ManageSalaryPermission = true;
      } else {
        UserPermission.forEach((element) => {
          if (element == "managesalary") {
            this.ManageSalaryPermission = true;
          }
        });
      }
    },
  },

  computed: {},

  async created() {
    this.getCompanyProfile();
    this.getUserPermission();
    this.fetchPosition();
    this.fetchBranch();
    this.fetchBenefits();
    this.fetchEmployeeLevel();
    this.fetchDepartment();
    await this.getRoleUser();
    await this.fetchComJobtitle();
    await this.fetchComEducationLevel();
  },
};
</script>

<style scoped lang="scss">
.form {
  margin-top: 40px;
  width: 900px;
  height: auto;
  margin-bottom: 80px;

  .form-title {
    font-family: $font-family;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-weight: normal;
      color: #0978ea;

      span {
        color: #0978ea;
        margin-right: 5px;
      }
    }
  }

  .personal-input {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
  }

  .removeContact {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i {
      font-size: 1.4rem;
      margin-top: 8px;
      color: #ff3d6a;
      cursor: pointer;
    }
  }

  .btn-addFieldContact {
    width: 200px;
    height: 41px;
    background-color: $main-btn-color;
    margin-bottom: 30px;
    border-radius: 4px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: $font-family;
  }

  .btn-salary {
    width: 200px;
  }
}

.scan-outside {
  margin-left: 30px;
  font-family: $font-family;
}

.add-village {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: $main-btn-color;
  position: absolute;
  right: -40px;
  top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    font-size: 22px;
    color: #ffffff;
  }
}
</style>
