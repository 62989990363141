<template>
  <div>
    <table id="mytable">
      <thead :style="{
        color: 'white !important',
        borderBottom: '1px solid black',
      }">
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                  width: 300px;
                ">
          ລະຫັດພະນັກງານ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ຊື່,ນາມສະກຸນ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ເພດ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          Email
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ບ້ານຢູ່
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ເມືອງຢູ່
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ປະເພດຂໍ້ມູນການຕິດຕໍ່
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ສັນຊາດ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ປະເພດບັດ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ພະແນກ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ຕຳແໜ່ງ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ສາຂາ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ລະດັບ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                  width: 300px;
                ">
          ປະເພດຈ້າງງານ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                  width: 300px;
                ">
          ເລີ່ມເຮັດວຽກ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                  width: 300px;
                ">
          ເງີນເດືອນພື້ນຖານ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                  width: 300px;
                ">
          ທະນາຄານ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                  width: 300px;
                ">
          ຊື່ບັນຊີທະນາຄານ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ສະແກນເຂົ້າອອກ
        </th>
        <th class="text-no-wrap" style="
                  color: black;
                  padding: 5px;
                  border: 1px solid black !important;
                  font-weight: 800;
                ">
          ສະແກນນອກພື້ນທີ່
        </th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in employees" :key="index">
          <td>
            <div v-if="item.emp_number.error_status">
              <div v-if="item.old == false">
                <span class="text-danger">{{
                  item.emp_number.value
                }}</span>
                <v-text-field outlined class="text-change" dense v-model="editData.emp_number"
                  @change="editImportingData(item.id, 'emp_number')"></v-text-field>
              </div>
              <div v-else>
                <!-- <v-text-field
                        outlined
                        class="text-change"
                        dense
                        v-model="item.emp_number.value"
                        @change="editImportingEmpNumber(item.id, 'emp_number', item.emp_number.value)"
                      ></v-text-field> -->
                <span>{{ item.emp_number.value }}</span>
              </div>
            </div>
            <div v-else>
              <div v-if="item.old == true">
                <v-text-field outlined class="text-change" dense v-model="item.emp_number.value"
                  @change="editImportingEmpNumber(item.id, 'emp_number', item.emp_number.value)"></v-text-field>
              </div>
              <div v-else>{{ item.emp_number.value }}</div>

            </div>
          </td>
          <td>
            <span v-if="item.old == true" color="red">
              {{ item.name }} {{ item.surname }}
            </span>
            <span v-else>
              {{ item.name }} {{ item.surname }}
            </span>
          </td>
          <td>{{ item.gender }}</td>
          <td>
            <div v-if="item.email.error_status">
              <span class="text-danger">{{
                item.email.value
              }}</span>
              <v-text-field outlined class="text-change" dense v-model="editData.email"
                @change="editImportingData(item.id, 'email')"></v-text-field>
            </div>
            <div v-else>{{ item.email.value }}</div>
          </td>
          <td>{{ item.village }}</td>
          <td>
            <div v-if="item.district.error_status">
              <span style="color: red;">error...</span>
              <v-autocomplete class="text-change" filled dense item-text="name" item-value="name" outlined
                outlinedColor="red" :items="item.district.data" hide-selected v-model="editData.district"
                @change="editImportingData(item.id, 'district')">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
            <div v-else>{{ item.district.value }}</div>
          </td>
          <td>
            <div v-if="item.contact_type.error_status">
              <span style="color: red;">error...</span>
              <v-autocomplete class="text-change" filled dense outlined :items="item.contact_type.data" hide-selected
                v-model="editData.contact_type" @change="editImportingData(item.id, 'contact_type')">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
            <div v-else>{{ item.contact_type.value }}</div>
          </td>
          <td>
            <div v-if="item.nationality.error_status">
              <span style="color: red;">error...</span>
              <v-autocomplete class="text-change" filled dense item-text="name" item-value="name" outlined
                :items="item.nationality.data" hide-selected v-model="editData.nationality"
                @change="editImportingData(item.id, 'nationality')">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
            <div v-else>{{ item.nationality.value }}</div>
          </td>
          <td>
            <div v-if="item.card_type.error_status">
              <span style="color: red;">error...</span>
              <v-autocomplete class="text-change" filled dense outlined :items="item.card_type.data" hide-selected
                v-model="editData.card_type" @change="editImportingData(item.id, 'card_type')">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
            <div v-else></div>
            {{ item.card_type.value }}
          </td>
          <td>
            <div v-if="item.department.error_status">
              <span style="color: red;">error...</span>
              <v-autocomplete class="text-change" filled dense item-text="name" item-value="name" outlined
                :items="item.department.data" hide-selected v-model="editData.department"
                @change="editImportingData(item.id, 'department')">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
            <div v-else>{{ item.department.value }}</div>
          </td>
          <td>
            <div v-if="item.position.error_status">
              <span style="color: red;">error...</span>
              <v-autocomplete class="text-change" filled dense item-text="name" item-value="name" outlined
                :items="item.position.data" hide-selected v-model="editData.position"
                @change="editImportingData(item.id, 'position')">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
            <div v-else></div>
            {{ item.position.value }}
          </td>
          <td>
            <div v-if="item.branch.error_status">
              <span style="color: red;">error...</span>
              <v-autocomplete class="text-change" filled dense item-text="name" item-value="name" outlined
                :items="item.branch.data" hide-selected v-model="editData.branch"
                @change="editImportingData(item.id, 'branch')">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
            <div v-else></div>
            {{ item.branch.value }}
          </td>
          <td>
            <div v-if="item.job_grade.error_status">
              <v-select :items="item.job_grade.data" outlined item-text="name" item-value="name" dense color="error"
                class="text-change" v-model="editData.job_grade"
                @change="editImportingData(item.id, 'job_grade')"></v-select>
            </div>
            <div v-else>{{ item.job_grade.value }}</div>
          </td>
          <td>
            <div v-if="item.employment_type.error_status">
              <v-select :items="item.employment_type.data" outlined dense color="error" class="text-change"
                v-model="editData.employment_type" @change="
                  editImportingData(item.id, 'employment_type')
                  "></v-select>
            </div>
            <div v-else>{{ item.employment_type.value }}</div>
          </td>
          <td>{{ item.start_date }}</td>
          <td>{{ $helpers.numberFormatter(item.base_salary) }}</td>
          <td>
            <div v-if="item.bank_name.error_status">
              <v-select :items="item.bank_name.data" outlined dense color="error" class="text-change"
                item-value="short_name" item-text="name" v-model="editData.bank_name"
                @change="editImportingData(item.id, 'bank_name')"></v-select>
            </div>
            <div v-else>{{ item.bank_name.value }}</div>
          </td>
          <td>
            <div v-if="!item.bank_name.error_status">
              {{ item.bank_account_name }} <br>
              {{ item.bank_account }}
            </div>
          </td>
          <td>
            <div v-if="item.finger_scan.error_status">
              <v-select :items="item.finger_scan.data" outlined dense color="error" class="text-change"
                v-model="editData.finger_scan" @change="editImportingData(item.id, 'finger_scan')"></v-select>
            </div>
            <div v-else>{{ item.finger_scan.value }}</div>
          </td>
          <td>
            <div v-if="item.scan_outside.error_status">
              <v-select :items="item.scan_outside.data" outlined dense color="error" class="text-change"
                v-model="editData.scan_outside" @change="editImportingData(item.id, 'scan_outside')"></v-select>
            </div>
            <div v-else>{{ item.scan_outside.value }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    employees: {},
  },
  data() {
    return {
      editData: {
        scan_outside: "",
        finger_scan: "",
        emergency_contact: "",
        job_grade: "",
        branch: "",
        position: "",
        department: "",
        card_type: "",
        nationality: "",
        contact_type: "",
        district: "",
        bank_name: "",
        emp_number: "",
        coloumn: "",
      },
    }
  },
  methods: {
    clear() {
      this.editData.emp_number = '';
      this.editData.scan_outside = '';
      this.editData.finger_scan = '';
      this.editData.emergency_contact = '';
      this.editData.job_grade = '';
      this.editData.branch = '';
      this.editData.position = '';
      this.editData.department = '';
      this.editData.card_type = '';
      this.editData.nationality = '';
      this.editData.contact_type = '';
      this.editData.district = '';
      this.editData.bank_name = '';
      this.editData.coloumn = '';
    },
    editImportingData(id, column) {
      this.editData.coloumn = column;
      this.$emit('success', { id: id, editData: this.editData });
      this.clear();
    },
    editImportingEmpNumber(import_id, coloumn, emp_number) {
      this.editData.emp_number = emp_number;
      this.editImportingData(import_id, coloumn);
      this.clear();
    },
  },
  created() {

  },
}
</script>

<style scoped lang="scss">
.text-change {
  font-size: 12px !important;
  padding: 0px !important;
  width: 150px;
  color: red !important;
}

.main-container {
  margin: 10px !important;
  padding-bottom: 0px !important;
}

.section-action {
  height: auto;
  margin-top: 20px;
}

.section-form-content {
  margin-top: 25px !important;
  padding: 0px;
}

.highlight--schedule {
  .schedule--byEmployee {
    background-color: rgba(0, 168, 255, 0.15);
    color: #719aff;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }

  .schedule--byPosition {
    background-color: rgba(239, 87, 119, 0.2);
    color: #f82c80;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }
}

.container-report {
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  padding: 10px;

  h1 {
    font-family: $font-family;
    font-size: 1.5rem;
    background-color: #ffffff;
    color: blue;
  }

  .card-report {
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-x: auto;
    //background-color: #FFFFFF;
    padding: 15px 0;
    font-family: $font-family;
    background-color: #ffffff;
    margin-bottom: 5px;
  }
}

.card-color-detail {
  margin-bottom: 25px !important;

  .color-detail {
    width: 100%;
    height: 80%;
    display: flex;
    border: 1px solid #eeeeee;
    align-items: center;
    border-radius: 16px;
    padding: 10px 10px;
    font-size: 14px !important;

    .circle-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: red;
    }

    p {
      font-family: $font-family;
      margin-bottom: 0;
      padding-left: 20px;
    }

    .standard {
      background-color: #ffffff;
      border: 1px solid #000000;
    }

    .is-leave {
      background-color: #80acf4;
    }

    .is-absence {
      background-color: red;
    }

    .is-skip {
      background-color: #fd9644;
    }

    .is-time-difference {
      background-color: #2f3542;
    }
  }
}

#mytable {
  font-family: $font-family;
  text-transform: capitalize;
  font-size: 12px !important;

  td {
    border: 1px solid;
  }

  .roster-chip {
    color: white;
    background: rgb(225, 175, 39) !important;
  }

  .emp-roster:hover {
    background: rgb(212, 212, 212);
  }

  .sum-work-sheet {
    color: blue;
    border: 1px solid #eae9e9;
    text-align: center;
    padding: 5px;
  }

  .ma-2 {
    font-size: 12px !important;
    margin: 2px !important;
  }
}

.td-part-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: 1px solid #000000;
}
</style>