<template>
  <div class="step-layout">
    <div class="step-content">
      <v-form class="form-contact" ref="form">
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1>
                <span><i class="fas fa-home"></i></span>{{ $t("Employee.form.address") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="6" md="6">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.province") }}</label>
                <v-select dense outlined v-model="province_id" :items="provinces" item-text="name" :rules="nameRules"
                  :hint="`${server_errors.province_id}`" persistent-hint item-value="id">
                </v-select>

                <!--                <v-select-->
                <!--                    dense-->
                <!--                    outlined-->
                <!--                    v-model="contact.province_id"-->
                <!--                    :items="provinces"-->
                <!--                    item-text="name"-->
                <!--                    :rules="nameRules"-->
                <!--                    :hint="`${server_errors.province_id}`"-->
                <!--                    persistent-hint-->
                <!--                    item-value="id"-->
                <!--                >-->
                <!--                </v-select>-->
              </v-col>

              <v-col cols="6" md="6">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.district") }}</label>
                <v-select outlined dense :items="districts" item-value="id" item-text="name" :rules="nameRules" required
                  v-model="items.district_id" :hint="`${server_errors.district_id}`" persistent-hint
                  @change="selectDistrictId($event)" :error-messages="`${this.contactEmployeeError &&
                    this.contactEmployeeError.district_id
                    ? this.contactEmployeeError.district_id
                    : ''
                    }`">
                </v-select>

                <!--                <v-select-->
                <!--                    outlined-->
                <!--                    dense-->
                <!--                    :items="districts"-->
                <!--                    item-value="id"-->
                <!--                    item-text="name"-->
                <!--                    :rules="nameRules"-->
                <!--                    required-->
                <!--                    v-model="contact.district_id"-->
                <!--                    :hint="`${server_errors.district_id}`"-->
                <!--                    persistent-hint-->
                <!--                    @change="selectDistrictId($event)"-->
                <!--                    :error-messages="`${-->
                <!--                    this.contactEmployeeError &&-->
                <!--                    this.contactEmployeeError.district_id-->
                <!--                      ? this.contactEmployeeError.district_id-->
                <!--                      : ''-->
                <!--                  }`"-->
                <!--                >-->
                <!--                </v-select>-->
              </v-col>
              <v-col cols="11" md="11" style="position: relative">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.village") }}</label>
                <!-- <v-select
                  @keydown.enter="focusNext"
                  dense
                  outlined
                  :rules="nameRules"
                  required
                  :items="listVillage"
                  item-text="name"
                  item-value="id"
                  v-model="items.village_id"
                  :hint="`${server_errors.village_id}`"
                  persistent-hint
                  :error-messages="`${
                    this.contactEmployeeError &&
                    this.contactEmployeeError.village_id
                      ? this.contactEmployeeError.village_id
                      : ''
                  }`"
                >
                </v-select> -->
                <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listVillage" hide-selected
                  :rules="nameRules" :hint="`${server_errors.village_id}`" persistent-hint requried
                  v-model="items.village_id" :error-messages="`${this.contactEmployeeError &&
                    this.contactEmployeeError.village_id
                    ? this.contactEmployeeError.village_id
                    : ''
                    }`">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <div class="add-village" v-if="this.districtId !== ''" @click="addNewVillage">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1>
                <span><i class="fas fa-phone-alt"></i></span>{{ $t("Employee.form.empContact") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col class="d-flex flex-row pa-0" cols="12" md="12" v-for="(item, index) in contacts" :key="index">
                <v-col cols="4" md="4">
                  <label class="label-input">
                    <span class="text-alert">*</span>
                    {{ $t("Employee.form.contact") }}</label>
                  <v-select @keydown.enter="focusNext" outlined dense :items="contactType" v-model="item.contact_type_id"
                    item-text="name" item-value="id" :hint="`${server_errors.contact_type_id}`" persistent-hint
                    :rules="nameRules" required>
                  </v-select>
                </v-col>
                <v-col cols="8" md="8">
                  <label class="label-input">
                    <span class="text-alert">*</span>
                    {{ $t("Employee.form.value") }}</label>
                  <v-text-field dense outlined :rules="nameRules" required v-model="item.value"
                    :hint="`${server_errors.value}`" persistent-hint @keydown.enter="focusNext">
                  </v-text-field>
                </v-col>
                <span>
                  <div class="removeContact" v-show="contacts.length > 1">
                    <i class="fas fa-minus-circle" @click="removeFieldContact(item)"></i>
                  </div>
                </span>
              </v-col>
              <v-col cols="12">
                <div class="btn-addFieldContact" @click="addFieldContact()">
                  <span class="mr-2"><i class="fal fa-plus"></i></span>{{ $t("Employee.form.addMore") }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1>
                <span><i class="fas fa-id-card"></i></span>{{ $t("Employee.form.Emergency Contact") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col>
                <label class="label-input">{{
                  $t("Employee.form.Emergency Contact")
                }}</label>
                <v-text-field @keydown.enter="focusNext" class="input-number" outlined dense type="number"
                  v-model="items.emergency_contact" :hint="`${server_errors.emergency_contact}`" persistent-hint>
                </v-text-field>
              </v-col>
              <v-col>
                <label class="label-input">{{
                  $t("Employee.form.relationShip")
                }}</label>
                <v-text-field @keydown.enter="focusNext" outlined dense v-model="items.relationship"
                  :hint="`${server_errors.relationship}`" persistent-hint>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1>
                <span><i class="fas fa-id-card"></i></span>{{ $t("Employee.form.identity") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="5">
                <label class="label-input">
                  <span class="text-alert">*</span>
                  {{ $t("Employee.form.cardType") }}
                </label>
                <v-select @keydown.enter="focusNext" outlined dense v-model="items.card_type" :items="identity_Card"
                  :item-text="item => $t('Employee.form.cardTypes.' + item.name)" item-value="card_type"
                  :rules="nameRules" required :hint="`${server_errors.card_type}`" persistent-hint :error-messages="`${this.contactEmployeeError &&
                    this.contactEmployeeError.card_type
                    ? this.contactEmployeeError.card_type
                    : ''
                    }`">
                </v-select>
              </v-col>

              <v-col cols="7">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.cardTypeValue")
                }}</label>
                <v-text-field @keydown.enter="focusNext" outlined dense :rules="nameRules" required
                  v-model="items.card_id" :hint="`${server_errors.card_id}`" persistent-hint :error-messages="`${this.contactEmployeeError &&
                    this.contactEmployeeError.card_id
                    ? this.contactEmployeeError.card_id
                    : ''
                    }`">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12" md="12">
          <v-btn class="btn-next btn-save-change" @click="validateForm()" :loading="btnLoading">
            {{ $t("Employee.form.next") }}
            <span><i class="fal fa-long-arrow-right"></i></span>
          </v-btn>
        </v-col>
      </v-form>
      <modalAddVillages :districtId="districtId" :visible="modalAddVillage" @success="onVillageCreated"
        @selectCurrentVillage="selectCurrentVillage" @change="(val) => (modalAddVillage = val)" />
    </div>
  </div>
</template>

<script>
import modalAddVillages from "@/components/employee/modal/modalAddVillage";
import { mapGetters } from "vuex";

export default {
  props: {
    contactEmployeeError: {},
  },
  components: {
    modalAddVillages,
  },
  data() {
    return {
      btnLoading: false,
      modalAddVillage: false,
      nameRules: [(v) => !!v || "* !!!"],
      rules: {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      contactType: [],
      identity_Card: [
        {
          card_type: "identity_card ",
          name: "identity_card",
        },
        {
          card_type: "social_security_card  ",
          name: "social_security_card",
        },
        {
          card_type: "passport",
          name: "passport",
        },
      ],
      provinces: [],
      districts: [],
      selectedProvince: {},
      selectedDistrict: {},
      filterDistricts: [],
      village: [],
      listVillage: [],
      province_id: "",
      districtId: "",

      items: {
        district_id: "",
        village_id: "",
        card_type: "",
        card_id: "",
        value: "",
        emergency_contact: "",
        relationship: "",
      },
      server_errors: {
        district_id: "",
        village_id: "",
        card_type: "",
        card_id: "",
        value: "",
        emergency_contact: "",
        relationship: "",
        province_id: "",
        contact_type_id: "",
      },
      contacts: [
        {
          contact_type_id: "",
          value: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      contact: "Employee/getContact",
    }),
  },
  methods: {
    isFormError() {
      const form = this.$refs.form;
      return Object.keys(form.errorBag).some((key) => form.errorBag[key]);
    },
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    async selectCurrentVillage(val) {
      if (val === 200) {
        await this.fetchVillage();
        const data = JSON.parse(JSON.stringify(this.listVillage));
        this.items.village_id = data.shift().id;
      }
    },
    async onVillageCreated() {
      await this.fetchVillage();
      this.selectDistrictId(this.districtId);
    },
    selectDistrictId(event) {
      this.districtId = event;
      this.$axios
        .get("list/village/" + this.districtId)
        .then((res) => {
          this.listVillage = res.data.villages;
          /* this.listVillage = this.village.filter(
            (item) => item.district_id === event
          ); */
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addNewVillage() {
      this.modalAddVillage = true;
    },
    fetchVillage() {
      return this.$axios.get(`list/addresses`).then((res) => {
        if (res.status === 200) {
          this.village = res.data.villages;
        }
      });
    },
    FetchDistrict() {
      this.$axios
        .get("list/addresses")
        .then((res) => {
          const items = res.data;
          this.provinces = items.provinces;
          this.selectedProvince = { ...this.provinces[0] };
          this.filterDistricts = items.districts;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    FilterDistricts(provinceID) {
      this.districts = this.filterDistricts.filter((item) => {
        if (item.province_id == provinceID) {
          return item;
        }
      });
    },
    addFieldContact() {
      this.contacts.push({
        contact_type_id: "",
        value: "",
        type: "",
      });
    },
    removeFieldContact(item) {
      let idx = this.contacts.indexOf(item);
      this.contacts.splice(idx, 1);
    },
    fetchContactType() {
      this.$axios.get("company/contact-type").then((res) => {
        if (res.data.code === 200) {
          this.contactType = res.data.data;
        }
      });
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },
    saveChange() {
      this.btnLoading = true;
      const items = {
        province_id: this.province_id,
        district_id: this.items.district_id,
        village_id: this.items.village_id,
        contacts: this.contacts ?? [],
        card_type: this.items.card_type,
        card_id: this.items.card_id,
        emergency_contact: this.items.emergency_contact,
        relationship: this.items.relationship,
      };
      this.$axios
        .post(`company/validate/info/employee/2`, items)
        .then((res) => {
          if (res.status === 200) {
            this.btnLoading = false;
            this.$store.commit("Employee/CONTACT_ITEM", items);
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
          if (error.response.status === 500) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              "server error"
            );
          }
        });
    },

    validateType(e) {
      if (e === 1) {
        return this.rules.email();
      }
    },
  },
  watch: {
    province_id: function (provinceID) {
      this.FilterDistricts(provinceID);
    },

    // contact: function (val) {
    //   this.FilterDistricts(val.province_id)
    // }
  },
  created() {
    this.FetchDistrict();
    this.fetchContactType();
    this.fetchVillage();
  },
};
</script>

<style scoped lang="scss">
.form-contact {
  margin-top: 40px;
  width: 900px;
  height: auto;
  margin-bottom: 100px;

  .form-title {
    font-family: $font-family;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-weight: normal;
      color: #0978ea;

      span {
        color: #0978ea;
        margin-right: 5px;
      }
    }
  }

  .personal-input {
    width: 100%;
    height: 100%;
    //  background-color: green;
    padding-top: 20px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
  }

  .removeContact {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i {
      font-size: 1.4rem;
      margin-top: 8px;
      color: #ff3d6a;
      cursor: pointer;
    }
  }

  .btn-addFieldContact {
    width: 200px;
    height: 41px;
    background-color: $main-btn-color;
    margin-bottom: 30px;
    border-radius: 4px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: $font-family;
  }

  .add-village {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: $main-btn-color;
    position: absolute;
    right: -40px;
    top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 22px;
      color: #ffffff;
    }
  }
}
</style>
