<template>
    <div class="step-layout">
        <v-dialog v-model="dialog_add_holiday" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Company Holiday</span>
                </v-card-title>
                <v-card-text>
                    <v-container>

                        <v-row class="personal-input">
                            <v-col class="d-flex flex-row pa-0" cols="12" md="12">
                                <v-col cols="5" md="5">
                                    <label class="label-input"><span class="text-alert">*</span>
                                        {{ $t("Employee.form.selectHoliday") }}</label>
                                    <v-select outlined dense :items="employeeHoliday" v-model="holiday_select.id"
                                        persistent-hint item-value="id" item-text="name"
                                        @change="handleEventSelectHoliday($event)">
                                    </v-select>
                                </v-col>
                                <v-col cols="3" md="3">
                                    <label class="label-input"><span class="text-alert">*</span>
                                        {{ $t("Employee.form.holidayAmount") }}</label>
                                    <v-text-field dense outlined v-model="holiday_select.amount" type="number"
                                        class="input-number" persistent-hint :disabled="disalbe_amount">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" md="4">
                                    <label class="label-input"><span class="text-alert">*</span>
                                        {{ $t("Employee.form.holidayRemaining") }}</label>
                                    <div class="remining-qty">
                                        <v-text-field dense outlined v-model="holiday_select.day_used" type="number"
                                            class="input-number" persistent-hint :placeholder="'Day'">
                                        </v-text-field>
                                        <v-text-field dense outlined v-model="holiday_select.hour_used" type="number"
                                            class="input-number" persistent-hint :placeholder="'Hour'">
                                        </v-text-field>
                                    </div>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="addFieldHoliday">
                        Add
                    </v-btn>
                    <v-btn color="red darken-1" text @click="closeModal">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        dialog_add_holiday: "",
        employee_holidays: []
    },
    data() {
        return {
            disalbe_amount: false,
            employeeHoliday: [],
            employeeHoliday1: [],
            holiday_select:
            {
                id: "",
                name: "",
                amount: "",
                day_used: "",
                hour_used: "",
            }
        }
    },
    watch: {
        employee_holidays() {
            this.checkAddHoliday();
        }
    },
    computed: {
        ...mapGetters({
            salary: "Employee/getSalary",
        }),
        filteredList() {
            return this.employeeHoliday1.filter(item => !this.employee_holidays.some(bItem => bItem.id === item.id));
        },
    },
    methods: {
        checkAddHoliday() {
            this.employeeHoliday = this.filteredList
        },

        closeModal() {
            this.$emit('close', false);
        },

        clearItemAdd() {
            this.holiday_select.id = '';
            this.holiday_select.name = '';
            this.holiday_select.day_used = '';
            this.holiday_select.hour_used = '';
            this.holiday_select.amount = '';
            this.checkAddHoliday();
        },

        addFieldHoliday() {
            if (this.holiday_select.id == '') return;
            this.closeModal();

            let item = {
                id: this.holiday_select.id,
                name: this.holiday_select.name,
                amount: this.holiday_select.amount,
                day_used: this.holiday_select.day_used,
                hour_used: this.holiday_select.hour_used,
            };
            this.$emit('add', item);
            this.clearItemAdd();
        },
        handleEventSelectHoliday(selectedId) {
            const matched = this.employeeHoliday.filter((i) => i.id == selectedId);
            if (matched && matched.length) {
                const company_holiday_date = matched[0].company_holiday_date;
                let amount = matched[0].amount;
                if (company_holiday_date.length > 0) {
                    // ກວດສອບວັນທີ່ເຂົ້າວຽກວ່າຈະໄດ້ຮັບມື້ພັກຈັກມື້
                    this.holiday_select.id = ''
                    if (!this.salary.start_date) {
                        this.$notification.ShowErrors(
                            "top-right",
                            "danger",
                            3000,
                            "ກະລຸນາປ້ອນວັນທີ່ເລີ່ມງານ"
                        );
                        return;
                    }
                    this.disalbe_amount = true;
                    let start_date = this.salary.start_date;
                    const remain_holiday = company_holiday_date.filter((item) => item >= start_date);
                    amount = remain_holiday.length;
                } else {
                    this.disalbe_amount = false;
                }
                this.holiday_select.id = selectedId;
                this.holiday_select.name = matched[0].name;
                this.holiday_select.amount = amount;
            }
        },
        fetchHoliday() {
            this.$axios
                .get(`company/list/holidays`)
                .then((res) => {
                    if (res.status === 200) {
                        this.employeeHoliday = res.data.data;
                        this.employeeHoliday1 = res.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

    },
    created() {
        this.fetchHoliday();
    },
}
</script>

<style lang="scss" scoped>
.remining-qty {
    display: flex;
    justify-content: space-around;
}

.form {
    margin-top: 40px;
    width: 900px;
    height: auto;
    margin-bottom: 80px;

    .form-title {
        font-family: $font-family;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        h1 {
            font-size: 1.2rem;
            letter-spacing: 1px;
            font-weight: normal;
            color: #0978ea;

            span {
                color: #0978ea;
                margin-right: 5px;
            }
        }
    }

    .personal-input {
        width: 100%;
        height: 100%;
        padding-top: 20px;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 20px;
    }

    .removeContact {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        i {
            font-size: 1.4rem;
            margin-top: 8px;
            color: #ff3d6a;
            cursor: pointer;
        }
    }

    .btn-addFieldContact {
        width: 200px;
        height: 41px;
        background-color: $main-btn-color;
        margin-bottom: 30px;
        border-radius: 4px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: $font-family;
    }

    .btn-salary {
        width: 200px;
    }
}
</style>